<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <relatorios-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getRelatoriosModelos()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatoriosModelosService from '@/services/RelatoriosModelosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    RelatoriosDataTable: () =>
      import('@/components/power-ups/relatorios-modelos/RelatoriosDataTable')
  },

  data: () => ({
    items: [],
    arrTour: [
      {
        element: '#btn-novo-relatorio-modelo',
        intro:
          'Clicando sobre este botão você poderá criar novos relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#relatorios-modelos-historico-search',
        intro: 'Neste campo você poderá pesquisar relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-edit-rel-m',
        intro:
          'Clicando sobre este botão você poderá editar seu relatório modelo, incluindo, alterando ou excluindo campos e filtros.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel-m',
        intro:
          'Aqui você acessa outras opções para seu relatório modelo, como deletá-lo e visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  mounted() {
    this.refreshData(this.getRelatoriosModelos);
  },

  methods: {
    getRelatoriosModelos() {
      this.items = [];
      this.loading = true;
      RelatoriosModelosService.index()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>
